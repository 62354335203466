
























import {
  Component, Vue, Prop
} from 'vue-property-decorator';

const INSTITUTION_NAME_DEFAULT = 'Explicaê';

@Component({})
export default class WrapperInstability extends Vue {
  get institution() {
    return this.$store.getters.profile.institution;
  }

  get institutionName() {
    return this.institution?.name || INSTITUTION_NAME_DEFAULT;
  }

  get logo() {
    const logoInstitution = this.institution?.image;
    return logoInstitution || require('@/assets/imgs/logo/logo-explicae.svg');
  }
}
