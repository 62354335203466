import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Instability extends Vue {
  created() {
    this.$store.commit('setIsShowNavbar', false);
  }

  beforeDestroy() {
    this.$store.commit('setIsShowNavbar', true);
  }

  goToDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }
}
